import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { firebaseContext } from '@/providers/firebase';
import LoaderFullscreen from '@/ui-kit/components/loaderFullscreen';


const WithPrivateComponent: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<'-1' | '0' | '1'>('0');
  const router = useRouter();
  const firebaseCtx = useContext(firebaseContext);

  useEffect(() => {
    const auth = getAuth(firebaseCtx.app);

    onAuthStateChanged(auth, (dbUser) => {
      setUser(dbUser?.uid ? '1' : '-1');
    });
  }, []);

  if (user === '1') {
    router.push('/account');
    return <LoaderFullscreen />;
  }

  if (user === '-1') {
    return <>{children}</>;
  }

  return <LoaderFullscreen />;
};

export default WithPrivateComponent;
