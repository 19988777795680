import { createContext } from 'react';
import { FirebaseApp } from 'firebase/app';

export interface FirebaseContext {
  app: FirebaseApp;
}

export const firebaseContext = createContext<FirebaseContext>(
  {} as FirebaseContext,
);
firebaseContext.displayName = 'FirebaseContext';
