import { ComponentType } from 'react';
import WithPublicComponent from './component';

export const WithPublic =
  <T extends object>(Component: ComponentType) =>
  (props: T): JSX.Element => (
    <WithPublicComponent>
      <Component {...{ ...props }} />
    </WithPublicComponent>
  );
