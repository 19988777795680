var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"jwE4rAK50lHAKsmaBXLFR"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

process.env.NEXT_PUBLIC_PRODUCTION === 'true' && (
  Sentry.init({
    dsn: SENTRY_DSN || 'https://cdfd6d2ee21d47e0b84b2ee78390f00f@o4504894101913600.ingest.sentry.io/4504955869396992',
    tracesSampleRate: 0.5,
  })
);
