import { ComponentType } from 'react';
import WithPrivateComponent from './component';

export const WithPrivate =
  <T extends object>(Component: ComponentType) =>
  (props: T): JSX.Element => (
    <WithPrivateComponent>
      <Component {...{ ...props }} />
    </WithPrivateComponent>
  );
