import { FC } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const Loader: FC<Props> = ({ className }) => (
  <svg
    {...{
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'none',
      viewBox: '0 0 24 24',
      className: cx(styles.loader, className),
    }}
  >
    <path
      {...{
        stroke: 'currentColor',
        strokeWidth: '2',
        d: 'M20 12c0-4.41828-3.5817-8-8-8-4.41828 0-8 3.58172-8 8 0 4.4183 3.58172 8 8 8',
      }}
    />
  </svg>
);

export default Loader;
