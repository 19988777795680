import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import intervalplural from 'i18next-intervalplural-postprocessor';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';

import '@/ui-kit/styles/variables.css';
import '@/ui-kit/styles/default.css';


import FirebaseProvider from '@/providers/firebase';
import { i18n } from '../../next-i18next.config';

i18next
  .use(initReactI18next)
  .use(intervalplural)
  .init({
    fallbackLng: i18n.defaultLocale,
    interpolation: { escapeValue: false },
  });

const App = ({ Component, pageProps }: AppProps) => (
  <FirebaseProvider>
    <Component {...pageProps} />
  </FirebaseProvider>
);

export default appWithTranslation(App);
